@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

$bubbleBg: var(--nitrobot-bubble-bg);
$bubbleText: var(--nitrobot-bubble-text);
$headerBg: var(--nitrobot-header-bg);
$headerText: var(--nitrobot-header-text);

.chatbot-bubble-wrapper {
  position: fixed;
  font-family: "Inter", sans-serif;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;

  p {
    margin: 0;
  }

  .bubble-opener {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);
    background-color: var(--nitrobot-bubble-bg);
    color: var(--nitrobot-bubble-text);

    svg {
      path {
        fill: var(--nitrobot-bubble-text);
      }
    }

    &:has(img) {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &:has(svg) {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .bubble-holder {
    height: 0 !important;
    overflow: hidden;
    width: 0;
    max-width: 100%;
    border: solid 1px #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    transition: opacity 0.5s ease;
    opacity: 0;
    display: flex;
    flex-direction: column;

    &.show {
      background-color: white;
      height: 650px !important;
      width: 450px !important;
      position: relative;
      opacity: 1;

      .bubble-header {
        padding: 0 10px;
        flex: 0 0 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 500;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .header-buttons {
          display: flex;
          gap: 10px;
          align-items: center;

          svg {
            width: 20px;
            height: 20px;
            cursor: pointer;
            transition: all 0.1s ease;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }

      .conversation-messages {
        flex: 1;
        position: relative;
        overflow-y: hidden;

        .error-message {
          padding: 7px;
          margin: 10px 10px 0 10px;
          line-height: 1.8;
          border-radius: 3px;
          background-color: #fee;
          border: 1px solid #edd;
          color: #a66;
          font-size: 14px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
        }

        .lead_form {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #f8f8f8;
          border-radius: 5px;
          padding: 20px;
          border: solid 1px #ccc;

          input {
            overflow: visible;
            height: var(--react-international-phone-height, 36px);
            box-sizing: border-box;
            padding: 0 8px;
            border: 1px solid
              var(--react-international-phone-border-color, gainsboro);
            border-radius: var(--react-international-phone-border-radius, 4px);
            margin: 0;
            background-color: var(
              --react-international-phone-background-color,
              white
            );
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            color: var(--react-international-phone-text-color, #222);
            font-family: inherit;
            font-size: var(--react-international-phone-font-size, 13px);
            margin-bottom: 10px;
            width: 100%;

            &:not(input[type="tel"]) {
              border-radius: 4px;
            }

            &:focus {
              outline: none;
            }
          }

          button.submit {
            cursor: pointer;
            border: solid 1px $headerBg;
            outline: none;
            padding: 7px 15px;
            border-radius: 5px;
            background-color: $headerText;
            color: $headerBg;

            svg {
              path {
                fill: $headerBg;
              }
            }

            &:hover,
            &:focus {
              background-color: $headerBg;
              color: $headerText;

              svg {
                path {
                  fill: $headerText;
                }
              }
            }

            svg {
              width: 15px;
              height: 15px;
            }

            .loading {
              animation: loading-animation 1.5s infinite;
            }

            @keyframes loading-animation {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          }
        }

        .conversation-messages-inner {
          padding: 0 10px;
          max-height: 100%;
          overflow-y: scroll;

          /* Style the scrollbar */
          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #d6d6d6;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: #a0a0a0;
          }

          .single_message {
            margin: 15px 0;
            display: flex;
            gap: 10px;
            align-items: center;

            &.user {
              justify-content: flex-start;
              flex-direction: row-reverse;

              .bubble {
                color: var(--nitrobot-header-text);
                background-color: var(--nitrobot-header-bg);
              }
            }

            &.ai {
              .bubble {
                background-color: #f6f6f6;
              }
            }

            .bubble {
              padding: 10px;
              border-radius: 5px;
              background-color: #fff;
              border: solid 1px #ccc;
            }
          }
        }
      }

      .bubble-form-holder {
        width: 100%;
        border-top: solid 1px #ccc;
        background-color: white;

        .suggestions {
          display: flex;
          overflow-x: auto;
          white-space: nowrap;
          max-height: 50px;
          font-size: 14px;
          padding: 10px;
          border-bottom: solid 1px #ccc;
          cursor: grab;

          /* Style the scrollbar */
          &::-webkit-scrollbar {
            height: 3px;
          }

          &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #d6d6d6;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: #a0a0a0;
          }

          .suggestion {
            display: inline-block;
            padding: 5px 8px;
            border-radius: 5px;
            background-color: $headerText;
            color: $headerBg;
            border: solid 1px $headerBg;
            cursor: pointer;
            user-select: none;

            &:hover,
            &:focus {
              background-color: $headerBg;
              color: $headerText;
            }

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        form {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          margin: 10px;
          border: solid 1px #ccc;
          gap: 10px;
          border-radius: 5px;

          input {
            width: 90%;
            border: none;
            outline: none;
            font-size: 14px;
            font-family: inherit;
            padding-right: 20px;
            resize: none;
            box-sizing: border-box;
            vertical-align: middle;

            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-track {
              background-color: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #d6d6d6;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background-color: #a0a0a0;
            }
          }

          button {
            cursor: pointer;
            width: 10%;
            height: 30px;
            background-color: #efefef;
            border: solid 1px $headerBg;
            outline: none;
            border-radius: 5px;
            background-color: $headerBg;

            &:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }

            svg {
              path {
                fill: $headerText;
              }
            }

            &:hover {
              background-color: $headerText;

              svg {
                path {
                  fill: $headerBg;
                }
              }
            }

            svg {
              width: 15px;
              height: 15px;
            }

            .loading {
              animation: loading-animation 1.5s infinite;
            }

            @keyframes loading-animation {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          }
        }

        p.attr {
          margin: 5px 10px;
          font-size: 14px;
          color: #999;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;

    .bubble-opener {
      position: fixed;
      bottom: 10px;
      right: 10px;
      margin: 0;
      z-index: 0;
    }

    .bubble-holder {
      border-radius: 0;

      &.show {
        height: 100vh !important;
        width: 100% !important;
        z-index: 1;
      }
    }
  }
}
