@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.chatbot-bubble-wrapper {
  position: fixed;
  font-family: "Inter", sans-serif;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.chatbot-bubble-wrapper p {
  margin: 0;
}
.chatbot-bubble-wrapper .bubble-opener {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: var(--nitrobot-bubble-bg);
  color: var(--nitrobot-bubble-text);
}
.chatbot-bubble-wrapper .bubble-opener svg path {
  fill: var(--nitrobot-bubble-text);
}
.chatbot-bubble-wrapper .bubble-opener:has(img) img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chatbot-bubble-wrapper .bubble-opener:has(svg) svg {
  width: 30px;
  height: 30px;
}
.chatbot-bubble-wrapper .bubble-holder {
  height: 0 !important;
  overflow: hidden;
  width: 0;
  max-width: 100%;
  border: solid 1px transparent;
  border-radius: 15px;
  box-shadow: 0px 8px 15px 6px rgba(0,0,0,0.1);
  transition: opacity 0.5s ease;
  opacity: 0;
  display: flex;
  flex-direction: column;
}
.chatbot-bubble-wrapper .bubble-holder.show {
  background-color: var(--nitrobot-body-bg);
  height: 80vh !important;
  width: 450px !important;
  position: relative;
  opacity: 1;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-header {
  padding: 0 20px;
  flex: 0 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-header .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 20px;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-header .title img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-header .header-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-header .header-buttons svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.1s ease;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-header .header-buttons svg:hover {
  transform: scale(1.1);
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages {
  flex: 1;
  position: relative;
  overflow-y: hidden;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .error-message {
  padding: 7px;
  margin: 10px 10px 0 10px;
  line-height: 1.8;
  border-radius: 3px;
  background-color: #fee;
  border: 1px solid #edd;
  color: #a66;
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8f8f8;
  padding: 20px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}

.lead_form_p{
  font-size: 15px;
  color: #999;
  text-align: center;
  padding-bottom: 15px;
}

.form_footer{
  display: flex;
  width: 100%;
  align-items: center;
  justify-items: end;
  justify-content: end;
  gap: 10px;
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form input {
  overflow: visible;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  /* border-bottom-left-radius: 0; */
  border-top-left-radius: 0;
  color: var(--react-international-phone-text-color, #222);
  font-family: inherit;
  font-size: var(--react-international-phone-font-size, 13px);
  margin-bottom: 10px;
  width: 100%;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form input:not(input[type=tel]) {
  border-radius: 4px;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form input:focus {
  outline: none;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit {
  cursor: pointer;
  border: solid 1px var(--nitrobot-header-bg);
  outline: none;
  padding: 7px 15px;
  border-radius: 5px;
  background-color: var(--nitrobot-header-text);
  color: var(--nitrobot-header-bg);
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit svg path {
  fill: var(--nitrobot-header-bg);
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit:hover, .chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit:focus {
  background-color: var(--nitrobot-header-bg);
  color: var(--nitrobot-header-text);
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit:hover svg path, .chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit:focus svg path {
  fill: var(--nitrobot-header-text);
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit svg {
  width: 15px;
  height: 15px;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .lead_form button.submit .loading {
  animation: loading-animation 1.5s infinite;
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner {
  padding: 0 10px 0 15px;
  max-height: 100%;
  min-height: 100%;
  overflow-y: scroll;
  /* Style the scrollbar */
  display: flex;
  flex-direction: column;
}

.grow{
  flex-grow: 1;
  width: 100%;
  background-color: transparent;
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner::-webkit-scrollbar {
  width: 5px;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner::-webkit-scrollbar-track {
  background-color: #f1f1f100;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 5px;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner::-webkit-scrollbar-thumb:hover {
  background-color: #b8c1cc;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .single_message {
  margin: 10px 0;
  display: flex;
  gap: 10px;
  align-items: center;
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .single_message.user {
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .single_message.human {
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .single_message.user .bubble {
  color: var(--nitrobot-user-message-text-color);
  background-color: var(--nitrobot-user-message-bg);
  line-break: anywhere;
  /* border-bottom-right-radius: 0px; */
}


.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .single_message.human .bubble {
  color: var(--nitrobot-user-message-text-color);
  background-color: var(--nitrobot-user-message-bg);
  line-break: anywhere;
  /* border-bottom-right-radius: 0px; */
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .single_message.ai .bubble {
  color:  var(--nitrobot-ai-message-text-color);
  background-color:  var(--nitrobot-ai-message-bg);
  line-break: anywhere;
  /* border-bottom-left-radius: 0px; */
}
.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .single_message .bubble {
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 70%;
  background-color: #fff;
  border: solid transparent 1px;
  line-break: anywhere;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder {
  width: 100%;
  background-color: var(--nitrobot-body-bg);
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .suggestions-container {
  display: flex;
  justify-content: end;
  background-color: var(--nitrobot-body-bg);
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .suggestions-container .suggestions {
  display: flex;
  justify-content: end;
  white-space: nowrap;
  flex-wrap: wrap-reverse;
  width: 75%;
  gap: 8px 4px;
  font-size: 14px;
  padding: 4px 0px 4px;
  cursor: grab;
  background-color: var(--nitrobot-body-bg);
}


.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .suggestions-container .suggestions .suggestion {
  display: inline-block;
  padding: 8px;
  border-radius: 10px;
  color: var(--nitrobot-suggestions-text-color);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .suggestions-container .suggestions .suggestion.bg {
 background-color: var(--nitrobot-suggestions-bg);
 border: solid 1px transparent; 
}

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .suggestions-container .suggestions .suggestion.border {
  border: solid 2px var(--nitrobot-suggestions-bg);
  background-color: white;
 }

.chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .suggestions-container .suggestions .suggestion:hover, .chatbot-bubble-wrapper .bubble-holder.show .conversation-messages .conversation-messages-inner .suggestions .suggestion.bg:focus {
  background-color: var(--nitrobot-suggestions-text-color);
  color: var(--nitrobot-suggestions-bg);
   border: solid 1px var(--nitrobot-suggestions-bg); 
}

/* .chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder .suggestions .suggestion:not(:last-child) {
  margin-right: 10px;
} */
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 8px;
  gap: 10px;
  margin: 0px 10px;
  margin-top: 5px;
  background-color: white;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: inherit;
  padding-right: 20px;
  resize: none;
  box-sizing: border-box;
  vertical-align: middle;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form input::-webkit-scrollbar {
  width: 5px;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form input::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form input::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 5px;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form input::-webkit-scrollbar-thumb:hover {
  background-color: #b8c1cc;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form button {
display: flex;
cursor: pointer;
width: 10%;
height: 30px;
background-color: #efefef;
border: solid 0px var(--nitrobot-header-bg);
outline: none;
border-radius: 5px;
background-color: white;  /* var(--nitrobot-header-bg); */
justify-content: center;
align-items: center;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form button:disabled {
  cursor: not-allowed;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form button svg path {
  color: var(--nitrobot-header-bg);
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form button:hover {
  background-color: var(--nitrobot-header-text);
  transform: scale(1.1);
}

/* .chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form button:hover svg path {
  fill: var(--nitrobot-header-bg);
} */

.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form button svg {
  width: 20px;
  height: 20px;
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder form button .loading {
  animation: loading-animation 1.5s infinite;
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.chatbot-bubble-wrapper .bubble-holder.show .bubble-form-holder p.attr {
  margin: 10px;
  font-size: 14px;
  color: #999;
  text-align: center;
}

a{
  list-style: none;
  font-size: 14px;
  color: #999;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .chatbot-bubble-wrapper {
    position: relative;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }
  .chatbot-bubble-wrapper .bubble-opener {
    position: fixed;
    bottom: 10px;
    right: 10px;
    margin: 0;
    z-index: 0;
  }
  .chatbot-bubble-wrapper .bubble-holder {
    border-radius: 0;
  }
  .chatbot-bubble-wrapper .bubble-holder.show {
    height: 100vh !important;
    width: 100% !important;
    z-index: 1;
  }
}/*# sourceMappingURL=index.css.map */

.icons{
  display: flex;
  align-items: center;
  justify-content: center;  
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0 4px;
  height: 20px; /* or the height of your bubble */
}

.loading-dot {
  background-color: var(--nitrobot-ai-message-text-color);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4x;
  /* Animation */
  animation: loading-dot 1s infinite ease-in-out;
}

/* The dots need to fade in and out at different times */
.loading-dot:nth-child(1) { animation-delay: 0s; }
.loading-dot:nth-child(2) { animation-delay: 0.2s; }
.loading-dot:nth-child(3) { animation-delay: 0.4s; }

/* Define the keyframes for the animation */
@keyframes loading-dot {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideInBottomRight {
  from {
    transform: translateX(100%) translateY(100%) scale(0);
    opacity: 0;
  }
  to {
    transform: translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes slideOutBottomRight {
  from {
    transform: translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translateX(100%) translateY(100%) scale(0);
    opacity: 0;
  }
}

.chatbot-bubble-wrapper .bubble-holder.animate-show {
  animation: slideInBottomRight 0.5s ease forwards;
}

.chatbot-bubble-wrapper .bubble-holder.animate-hide {
  animation: slideOutBottomRight 0.5s ease forwards;
}

.chatbot-bubble-wrapper .bubble-opener {
  transition: transform 0.3s ease; /* Ensure smooth scaling transition */
}

.chatbot-bubble-wrapper .bubble-opener:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

